import React from "react";
import PageTitle from "./common/pageTitle";

const Extensions = () => {
  return (
    <>
      <PageTitle pageTitle="Readsy - Get Extension" />

      <div className="max-w-xl  py-12">
        <h2 className="text-2xl font-bold text-gray-800 dark:text-white md:text-3xl">
          Select your favorite browser below:
        </h2>
        <p className="mt-4 text-gray-600 dark:text-gray-400">
          As long as it's Firefox or Chrome.
        </p>

        <div className="mt-8">
          <a
            href="https://chrome.google.com/webstore/detail/readsy/nopgbkabhipfhbciolfjhmkflphjfkca?hl=en&authuser=0"
            className="px-5 mx-2 py-2 font-semibold text-white transition-colors duration-200 transform bg-blue-600 rounded-md hover:bg-blue-400 focus:outline-none focus:bg-blue-400"
          >
            Readsy for Chrome
          </a>
        </div>
        <div className="mt-8">
          <a
            href="https://addons.mozilla.org/en-US/firefox/addon/readsyapp/"
            className="px-5 mx-2 py-2 font-semibold text-white transition-colors duration-200 transform bg-blue-600 rounded-md hover:bg-blue-400 focus:outline-none focus:bg-blue-400"
          >
            Readsy for Firefox
          </a>
        </div>
        
      </div>
    </>
  );
};

export default Extensions;
