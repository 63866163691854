import React, { useEffect } from "react";

import { Routes, Route } from "react-router-dom";

import PageTitle from "./components/common/pageTitle";

import Home from "./components/home";
import Register from "./components/userComponents/register";
import Login from "./components/userComponents/login";
import Logout from "./components/userComponents/logout";
import ConnectExtension from "./components/userComponents/connectExtension";
import ForgotPasswordForm from "./components/userComponents/forgotPwd";
import ResetPasswordForm from "./components/userComponents/resetPwd";
import ConfirmAccount from "./components/userComponents/confirmAccount";
import UserAccount from "./components/userAccount";
import Premium from "./components/premium";
import FAQ from "./components/faq";
import Team from "./components/team";
import ContactUs from "./components/contactUs";
import Extensions from "./components/extensions";
import NotFound from "./components/notFound";
import RequireAuth from "./components/requireAuth";
import Layout from "./components/layout";

import "react-toastify/dist/ReactToastify.css";
import "./App.css";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import ReactGA from "react-ga";
import { default as ReactGA4 } from "react-ga4";

const TRACKING_ID = "UA-239235600-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
ReactGA4.initialize("G-8RH5MCC0KY");

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// const stripePromise = loadStripe(
//   "pk_test_51LFPPyLlURxf2NA2RY1mZs2K2VrCaHBpn4YkIfwhWghNpg9YU3vgQGS737u3wmt80gh9nWg2ygL96RpplWRv2xHL00E1D1HvAH"
// );

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactGA4.send({ hitType: "pageview", page: window.location.pathname });
  }, []);
  return (
    <>
      <PageTitle pageTitle="Readsy" />

      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/home" element={<Home />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/connect-extension" element={<ConnectExtension />} />
          <Route path="/confirm-account" element={<ConfirmAccount />} />
          <Route path="/forgot-password" element={<ForgotPasswordForm />} />
          <Route path="/reset-password/:id" element={<ResetPasswordForm />} />
          <Route element={<RequireAuth />}>
            <Route path="/user-account" element={<UserAccount />} />
          </Route>
          {/* <Route path="/premium" element={<Premium />} /> */}
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/faq" element={<FAQ />} />

          <Route path="/team" element={<Team />} />
          <Route path="/extensions" element={<Extensions />} />
          <Route path="/" element={<Home />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
