import { useEffect } from "react";
import auth from "../../services/authService";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../authProvider";

export const Logout = () => {
  const { setAuth } = useContext(AuthContext);
  const navigate = useNavigate();
  useEffect(() => {
    setAuth(null);
    navigate("/login");
    auth.logout();
  });
  return null;
};

export default Logout;
