import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Joi from "joi-browser";
import { toast } from "react-toastify";

import PageTitle from "../common/pageTitle";
import auth from "../../services/authService";
import useAuth from "../../hooks/useAuth";
import { validate } from "../../services/validationService";
import { inputFormat, normalSubmitButtonStyle } from "../common/styleClasses";

const LoginForm = () => {
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/home";

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [errors, setErrors] = useState({});

  const schema = {
    username: Joi.string().required().label("Username"),
    password: Joi.string().required().label("Password"),
  };

  useEffect(() => {
    if (auth.getCurrentUser()) window.location = "/home";
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate({ username, password }, schema)) {
      // try {
      await auth.login(username, password);
      const currentUser = auth.getCurrentUser();
      if (currentUser) {
        const accessToken = localStorage.getItem("readsyToken");
        const roles = currentUser.roles;
        setAuth({ ...currentUser, accessToken });

        toast.success(`Login successful, Welcome ${currentUser.firstName}`);

        navigate(from, { replace: true });
      }
    }
  };

  return (
    <React.Fragment>
      <PageTitle pageTitle="Readsy - Login" />
      <div className="bg-white dark:bg-gray-900 mx-auto content-center my-16">
        <div className="w-full max-w-sm mx-auto overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800">
          <h1 className="text-2xl font-semibold text-gray-700 capitalize dark:text-white">
            Welcome back!
          </h1>
          <div className="px-6 py-4">
            <form onSubmit={handleSubmit}>
              <div className="w-full mt-4">
                <input
                  className={inputFormat}
                  type="username"
                  placeholder="Email"
                  aria-label="Email"
                  onChange={(e) => setUsername(e.target.value)}
                  value={username}
                  required
                  autoComplete="on"
                  error={errors["username"]}
                />
              </div>

              <div className="w-full mt-4">
                <input
                  className={inputFormat}
                  type="password"
                  placeholder="Password"
                  aria-label="Password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  required
                  autoComplete="on"
                  error={errors["password"]}
                />
              </div>

              <div className="flex items-center justify-between mt-4">
                <a
                  href="/forgot-password"
                  className="text-sm text-gray-600 dark:text-gray-200 hover:text-gray-500"
                >
                  Forgot Password?
                </a>

                <button
                  className={normalSubmitButtonStyle}
                  type="submit"
                  disabled={validate({ username, password }, schema)}
                >
                  Login
                </button>
              </div>
            </form>
          </div>

          <div className="flex items-center justify-center py-4 text-center bg-gray-50 dark:bg-gray-700">
            <span className="text-sm text-gray-600 dark:text-gray-200">
              Don't have an account?{" "}
            </span>

            <a
              href="/register"
              className="mx-2 text-sm font-bold text-blue-500 dark:text-blue-400 hover:underline"
            >
              Register
            </a>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LoginForm;
