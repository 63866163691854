import React, { useState, useEffect } from "react";
import PageTitle from "./common/pageTitle";
import Joi from "joi-browser";
import auth from "../services/authService";
import comm from "../services/commService";
import { normalSubmitButtonStyle } from "./common/styleClasses";
import { validate } from "../services/validationService";
import { toast } from "react-toastify";
import { MailIcon, LocationMarkerIcon } from "@heroicons/react/solid";

const logo = require("../media/aboutReadingImage.jpg");

const ContactUs = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [emailSent, setEmailSent] = useState(false);

  const schema = {
    name: Joi.string().min(1).max(500).required().label("Name"),
    email: Joi.string().min(1).max(500).required().email(),
    message: Joi.string().min(1).max(10000).required().label("Message"),
  };
  useEffect(() => {
    try {
      setEmail(auth.getCurrentUser().email);
    } catch (e) {}
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate({ name, email, message }, schema)) {
      try {
        setEmailSent(true);
        comm.contactUsSend({ name, email, message });
      } catch (error) {
        if (error.response && error.response.status === 400) {
          toast.error(error.response.data);
        }
      }
    }
  };
  return (
    <>
      <PageTitle pageTitle="Readsy - Contact Us" />

      {emailSent ? (
        <>
          <section className="w-full max-w-2xl px-6 py-4 mx-auto bg-white rounded-md shadow-md dark:bg-gray-800">
            <h2 className="text-3xl font-semibold text-center text-gray-800 dark:text-white">
              Get in touch
            </h2>
            <p className="mt-3 text-center text-gray-600 dark:text-gray-400">
              Comment? Question? Issue? Use the form below to reach out.
            </p>

            <div className="flex flex-row justify-evenly">
              <a
                href="https://www.google.com/maps/place/Boston,+MA"
                className="flex flex-col items-center px-4 py-3 text-gray-700 transition-colors duration-200 transform rounded-md dark:text-gray-200 hover:bg-blue-200 dark:hover:bg-blue-500"
              >
                <LocationMarkerIcon className="h-5 w-5" />

                <span className="mt-2">Boston, MA</span>
              </a>

              <a
                href="mailto:info@readsyapp.com"
                className="flex flex-col items-center px-4 py-3 text-gray-700 transition-colors duration-200 transform rounded-md dark:text-gray-200 hover:bg-blue-200 dark:hover:bg-blue-500"
              >
                <MailIcon className="h-5 w-5" />

                <span className="mt-2">info@Readsyapp.com</span>
              </a>
            </div>
            <h1 className="text-2xl mt-6 font-semibold text-gray-700 capitalize dark:text-white">
              Thanks for sending us a note.
            </h1>
            <h2 className="text-lg mt-6 text-gray-700 dark:text-white">
              A copy of the message has been emailed to the account provided as
              confirmation.
            </h2>
          </section>
        </>
      ) : (
        <section className="w-full max-w-2xl px-6 py-4 mx-auto bg-white rounded-md shadow-md dark:bg-gray-800">
          <h2 className="text-3xl font-semibold text-center text-gray-800 dark:text-white">
            Get in touch
          </h2>
          <p className="mt-3 text-center text-gray-600 dark:text-gray-400">
            Comment? Question? Issue? Use the form below to reach out.
          </p>

          <div className="flex flex-row justify-evenly">
            <a
              href="https://www.google.com/maps/place/Boston,+MA"
              className="flex flex-col items-center px-4 py-3 text-gray-700 transition-colors duration-200 transform rounded-md dark:text-gray-200 hover:bg-blue-200 dark:hover:bg-blue-500"
            >
              <LocationMarkerIcon className="h-5 w-5" />

              <span className="mt-2">Boston, MA</span>
            </a>

            <a
              href="mailto:info@Readsyapp.com"
              className="flex flex-col items-center px-4 py-3 text-gray-700 transition-colors duration-200 transform rounded-md dark:text-gray-200 hover:bg-blue-200 dark:hover:bg-blue-500"
            >
              <MailIcon className="h-5 w-5" />

              <span className="mt-2">info@Readsyapp.com</span>
            </a>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="mt-6 ">
              <div className="items-center -mx-2 md:flex">
                <div className="w-full mx-2">
                  <label className="block mb-2 text-sm font-medium text-gray-600 dark:text-gray-200">
                    Name
                  </label>

                  <input
                    className="block w-full px-4 py-2 text-gray-700 bg-white border rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 dark:focus:border-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
                    type="name"
                    placeholder="Name"
                    aria-label="Name"
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                    required
                    autoComplete="off"
                    error={errors["name"]}
                  />
                </div>

                <div className="w-full mx-2 mt-4 md:mt-0">
                  <label className="block mb-2 text-sm font-medium text-gray-600 dark:text-gray-200">
                    E-mail
                  </label>

                  <input
                    className="block w-full px-4 py-2 text-gray-700 bg-white border rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 dark:focus:border-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
                    type="email"
                    placeholder="Email"
                    aria-label="Email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    required
                    autoComplete="off"
                    error={errors["email"]}
                  />
                </div>
              </div>

              <div className="w-full mt-4">
                <label className="block mb-2 text-sm font-medium text-gray-600 dark:text-gray-200">
                  Message
                </label>

                <textarea
                  className="block w-full h-40 px-4 py-2 text-gray-700 bg-white border rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 dark:focus:border-blue-300 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                  type="message"
                  placeholder="Message"
                  aria-label="Message"
                  onChange={(e) => setMessage(e.target.value)}
                  value={message}
                  required
                  autoComplete="off"
                ></textarea>
              </div>

              <div className="flex justify-center mt-6">
                <button
                  className={normalSubmitButtonStyle}
                  type="submit"
                  disabled={validate({ name, email, message }, schema)}
                >
                  Send Message
                </button>
              </div>
            </div>
          </form>
        </section>
      )}
    </>
  );
};

export default ContactUs;
