import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import PageTitle from "./common/pageTitle";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import { validate } from "../services/validationService";

import auth from "../services/authService";
import AuthContext from "./authProvider";
const downloadImg = require("../media/dog-reading.jpg");

const Home = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [errors, setErrors] = useState({});
  const { auth: authUser, setAuth } = useContext(AuthContext);

  useEffect(() => {
    const currentUser = auth.getCurrentUser();
    const accessToken = localStorage.getItem("token");
    if (!authUser && accessToken) setAuth({ ...currentUser, accessToken });
  }, [authUser]);

  const schema = {
    username: Joi.string()
      .required()
      .email({ minDomainSegments: 2 })
      .label("Username"),
  };
  const handleSubmit = async (e) => {
    const validationResult = validate({ username }, schema);

    e.preventDefault();
    if (!validationResult) {
      try {
        const registrationConfirmation = await auth.signup({
          email: username,
        });

        localStorage.setItem("readsyUserEmailUnverified", username);

        toast.success("Thanks for signing up!")
        // navigate("/register", { replace: true });
      } catch (error) {
        if (error.response && error.response.status === 400) {
          toast.error(error.response.data);
        }
      }
    } else {
      toast.error(validationResult["username"]);
    }
  };
  return (
    <>
      <PageTitle pageTitle="Readsy - Home" />
      {!authUser ? (
        <section className="bg-gray-100 dark:bg-gray-900">
          <div className="container px-4 py-2 mx-auto lg:flex lg:items-center lg:justify-between">
            
            <h4 className=" font-semibold tracking-tight text-gray-800  dark:text-white">
              Sign up today!
            </h4>
            <div className="mt-8 lg:mt-0">
              <div className="flex flex-col space-y-3 sm:space-y-0 sm:flex-row sm:-mx-2">
              <form onSubmit={handleSubmit}>
                  <input
                    className="px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-md sm:mx-2 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
                    type="username"
                    placeholder="Email Address"
                    aria-label="Email"
                    onChange={(e) => setUsername(e.target.value)}
                    value={username}
                    required
                    autoComplete="on"
                    error={errors["username"]}
                  />
                  <span>
                  <button
                    className="px-5 mx-2 py-2 font-semibold text-white transition-colors duration-200 transform bg-blue-600 rounded-md hover:bg-blue-400 focus:outline-none focus:bg-blue-400"
                    type="submit"
                  >
                    Sign-up for News
                  </button>
                  <button className="px-5 mx-2 py-2 font-semibold text-white transition-colors duration-200 transform bg-blue-600 rounded-md hover:bg-blue-400 focus:outline-none focus:bg-blue-400"
                  >
                  <a
                  href="/extensions"
                  >
                    Get the Extension
                  </a>
                  </button>
                  
                    </span>
                  
                  
                </form>
                
              </div>
            </div>
            <a href="https://www.producthunt.com/products/readsy-3?utm_source=badge-follow&utm_medium=badge&utm_souce=badge-readsy&#0045;3" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/follow.svg?product_id=501819&theme=light" style={{height: '2.5rem'}} ></img></a>
          </div>
        </section>
      ) : (
        <></>
      )}

      <section className="bg-white dark:bg-gray-900">
        <div className="container px-6 py-10 mx-auto">
          <h1 className="text-3xl font-semibold text-gray-800 capitalize lg:text-4xl dark:text-white">
            Reading Made Easy
          </h1>

          <div className="mt-2">
            <span className="inline-block w-40 h-1 rounded-full bg-blue-500"></span>
          </div>

          <div><h2 className="mt-2 text-xl font-semibold text-blue-600 capitalize lg:text-xl dark:text-white">
            Choose from Several Default Formats or Create Your Own
          </h2></div>
          <div className="mt-2">
            <span className="inline-block w-40 h-1 rounded-full bg-blue-500"></span>
          </div>

          <div className="grid grid-cols-1 gap-8 mt-8 xl:mt-12 xl:gap-12 md:grid-cols-1 xl:grid-cols-1">
            <div className="text-left p-8 space-y-3 border-2 border-blue-400 dark:border-blue-300 rounded-xl">
            <div><h2 className="text-xl font-semibold text-gray-800 capitalize lg:text-xl dark:text-white">
            Default Highlighting
          </h2></div>
              <p>
                <span className="readsyon readsyProp  hl">Readsy</span>{" "}
                <span className="readsyon readsyKey VB  hl ">highlights</span>{" "}
                <span className="readsyon readsyKey JJ  hl ">key</span>{" "}
                <span className="readsyon readsyKey NN  hl ">text</span>{" "}
                <span className="readsyon readsyNon  fnt">on</span>{" "}
                <span className="readsyon readsyKey NN  hl ">webpages</span>{" "}
                <span className="readsyon readsyNon  fnt">to</span>{" "}
                <span className="readsyon readsyKey VB  hl ">help</span>{" "}
                <span className="readsyon readsyKey NN  hl ">users</span>{" "}
                <span className="readsyon readsyKey VB  hl ">consume</span>{" "}
                <span className="readsyon readsyKey NN  hl ">information</span>{" "}
                <span className="readsyon readsyKey JJ  hl ">more</span>{" "}
                <span className="readsyon readsyNon  fnt">easily.</span>{" "}
                <span className="readsyon readsyNon  fnt">The</span>{" "}
                <span className="readsyon readsyKey NN  hl ">browser</span>{" "}
                <span className="readsyon readsyKey NN  hl ">extension</span>{" "}
                <span className="readsyon readsyKey VB  hl ">analyzes</span>{" "}
                <span className="readsyon readsyKey NN  hl ">sentence</span>{" "}
                <span className="readsyon readsyKey NN  hl ">
                  parts-of-speech
                </span>{" "}
                <span className="readsyon readsyNon  fnt">and</span>{" "}
                <span className="readsyon readsyKey VB  hl ">adds</span>{" "}
                <span className="readsyon readsyKey NN  hl ">customizable</span>{" "}
                <span className="readsyon readsyKey VB  hl ">formatting</span>{" "}
                <span className="readsyon readsyNon  fnt">around</span>{" "}
                <span className="readsyon readsyNon  fnt">each</span>{" "}
                <span className="readsyon readsyKey NN  hl ">item.</span>{" "}
                <span className="readsyon readsyNon  fnt">This</span>{" "}
                <span className="readsyon readsyKey VB  hl ">allows</span>{" "}
                <span className="readsyon readsyKey NN  hl ">users</span>{" "}
                <span className="readsyon readsyNon  fnt">to</span>{" "}
                <span className="readsyon readsyKey VB  hl ">skip</span>{" "}
                <span className="readsyon readsyNon  fnt">over</span>{" "}
                <span className="readsyon readsyKey NN  hl ">text</span>{" "}
                <span className="readsyon readsyNon  fnt">like</span>{" "}
                <span className="readsyon readsyKey NN  hl ">prepositions</span>{" "}
                <span className="readsyon readsyNon  fnt">and</span>{" "}
                <span className="readsyon readsyKey NN  hl ">
                  conjunctions,
                </span>{" "}
                <span className="readsyon readsyNon  fnt">and</span>{" "}
                <span className="readsyon readsyKey NN  hl ">focus</span>{" "}
                <span className="readsyon readsyNon  fnt">on</span>{" "}
                <span className="readsyon readsyKey NN  hl ">words</span>{" "}
                <span className="readsyon readsyKey JJ  hl ">necessary</span>{" "}
                <span className="readsyon readsyNon  fnt">for</span>{" "}
                <span className="readsyon readsyKey JJ  hl ">general</span>{" "}
                <span className="readsyon readsyKey NN  hl ">
                  comprehension
                </span>{" "}
                <span className="readsyon readsyKey NN  hl">(nouns,</span>{" "}
                <span className="readsyon readsyKey NN  hl ">verbs,</span>{" "}
                <span className="readsyon readsyNon  fnt">and</span>{" "}
                <span className="readsyon readsyKey NN  hl ">adjectives).</span>{" "}
                <span className="readsyon readsyKey VB  hl">Give</span>{" "}
                <span className="readsyon readsyNon  fnt">it</span>{" "}
                <span className="readsyon readsyNon  fnt">a</span>{" "}
                <span className="readsyon readsyKey VB  hl ">try!</span>{" "}
                <span className="readsyon readsyKey VB  hl ">Try</span>{" "}
                <span className="readsyon readsyKey VB  hl ">focusing</span>{" "}
                <span className="readsyon readsyNon  fnt">on</span>{" "}
                <span className="readsyon readsyNon  fnt">the</span>{" "}
                <span className="readsyon readsyKey VB  hl ">highlighted</span>{" "}
                <span className="readsyon readsyKey NN  hl ">words</span>{" "}
                <span className="readsyon readsyNon  fnt">and</span>{" "}
                <span className="readsyon readsyKey VB  hl ">see</span>{" "}
                <span className="readsyon readsyNon  fnt">how</span>{" "}
                <span className="readsyon readsyNon  fnt">fast</span>{" "}
                <span className="readsyon readsyNon  fnt">you</span>{" "}
                <span className="readsyon readsyNon  fnt">can</span>{" "}
                <span className="readsyon readsyKey VB  hl ">read.</span>
              </p>
              <p>
                <span className="readsyon readsyNon  fnt">The</span>{" "}
                <span className="readsyon readsyKey NN  hl ">fact</span>{" "}
                <span className="readsyon readsyKey VB  hl ">is,</span>{" "}
                <span className="readsyon readsyKey JJ  hl ">many</span>{" "}
                <span className="readsyon readsyKey NN  hl ">people</span>{" "}
                <span className="readsyon readsyKey VB  hl ">skim</span>{" "}
                <span className="readsyon readsyKey NN  hl ">content</span>{" "}
                <span className="readsyon readsyNon  fnt">when</span>{" "}
                <span className="readsyon readsyNon  fnt">they</span>{" "}
                <span className="readsyon readsyKey VB  hl ">read,</span>{" "}
                <span className="readsyon readsyNon  fnt">or</span>{" "}
                <span className="readsyon readsyNon  fnt">they</span>{" "}
                <span className="readsyon readsyNon  fnt">simply</span>{" "}
                <span className="readsyon readsyKey VB  hl ">read</span>{" "}
                <span className="readsyon readsyNon  fnt">the</span>{" "}
                <span className="readsyon readsyNum  hl">first</span>{" "}
                <span className="readsyon readsyKey NN  hl ">paragraph</span>{" "}
                <span className="readsyon readsyNon  fnt">of</span>{" "}
                <span className="readsyon readsyKey JJ  hl ">online</span>{" "}
                <span className="readsyon readsyKey NN  hl ">articles</span>{" "}
                <span className="readsyon readsyNon  fnt">and</span>{" "}
                <span className="readsyon readsyKey NN  hl ">move</span>{" "}
                <span className="readsyon readsyNon  fnt">on.</span>{" "}
                <span className="readsyon readsyProp  hl">Readsy</span>{" "}
                <span className="readsyon readsyKey VB  hl ">is</span>{" "}
                <span className="readsyon readsyKey VB  hl ">aimed</span>{" "}
                <span className="readsyon readsyNon  fnt">at</span>{" "}
                <span className="readsyon readsyKey VB  hl ">helping</span>{" "}
                <span className="readsyon readsyNon  fnt">those</span>{" "}
                <span className="readsyon readsyKey NN  hl ">people</span>{" "}
                <span className="readsyon readsyKey VB  hl ">read</span>{" "}
                <span className="readsyon readsyKey OT  hl ">faster,</span>{" "}
                <span className="readsyon readsyKey VB  hl ">read</span>{" "}
                <span className="readsyon readsyNon  fnt">longer</span>{" "}
                <span className="readsyon readsyNon  fnt">and</span>{" "}
                <span className="readsyon readsyKey VB  hl ">retain</span>{" "}
                <span className="readsyon readsyKey JJ  hl ">more</span>{" "}
                <span className="readsyon readsyNon  fnt">than</span>{" "}
                <span className="readsyon readsyNon  fnt">they</span>{" "}
                <span className="readsyon readsyNon  fnt">otherwise</span>{" "}
                <span className="readsyon readsyNon  fnt">might.</span>{" "}
                <span className="readsyon readsyNon  fnt">It's</span>{" "}
                <span className="readsyon readsyNon  fnt">not</span>{" "}
                <span className="readsyon readsyKey VB  hl ">intended</span>{" "}
                <span className="readsyon readsyNon  fnt">for</span>{" "}
                <span className="readsyon readsyNon  fnt">all</span>{" "}
                <span className="readsyon readsyKey NN  hl ">readers</span>{" "}
                <span className="readsyon readsyNon  fnt">or</span>{" "}
                <span className="readsyon readsyNon  fnt">all</span>{" "}
                <span className="readsyon readsyKey NN  hl ">types</span>{" "}
                <span className="readsyon readsyNon  fnt">of</span>{" "}
                <span className="readsyon readsyKey NN  hl ">content,</span>{" "}
                <span className="readsyon readsyNon  fnt">but</span>{" "}
                <span className="readsyon readsyNon  fnt">the</span>{" "}
                <span className="readsyon readsyNon  fnt">side-by-side</span>{" "}
                <span className="readsyon readsyKey NN  hl ">comparison</span>{" "}
                <span className="readsyon readsyKey VB  hl ">is</span>{" "}
                <span className="readsyon readsyKey JJ  hl ">compelling.</span>
              </p>
              <p>
                <span className="readsyon readsyProp  hl">Readsy</span>{" "}
                <span className="readsyon readsyNon  fnt">can</span>{" "}
                <span className="readsyon readsyNon  fnt">easily</span>{" "}
                <span className="readsyon readsyKey VB  hl ">be</span>{" "}
                <span className="readsyon readsyKey VB  hl ">toggled</span>{" "}
                <span className="readsyon readsyNon  fnt">on/off,</span>{" "}
                <span className="readsyon readsyNon  fnt">and</span>{" "}
                <span className="readsyon readsyNon  fnt">the</span>{" "}
                <span className="readsyon readsyKey NN  hl ">colors</span>{" "}
                <span className="readsyon readsyKey VB  hl ">are</span>{" "}
                <span className="readsyon readsyNon  fnt">fully</span>{" "}
                <span className="readsyon readsyKey NN  hl ">
                  customizable.
                </span>{" "}
                <span className="readsyon readsyNon  fnt">You</span>{" "}
                <span className="readsyon readsyNon  fnt">can</span>{" "}
                <span className="readsyon readsyNon  fnt">even</span>{" "}
                <span className="readsyon readsyKey VB  hl ">turn</span>{" "}
                <span className="readsyon readsyNon  fnt">on</span>{" "}
                <span className="readsyon readsyKey VB  hl ">highlights</span>{" "}
                <span className="readsyon readsyNon  fnt">just</span>{" "}
                <span className="readsyon readsyNon  fnt">for</span>{" "}
                <span className="readsyon readsyKey JJ  hl ">certain</span>{" "}
                <span className="readsyon readsyKey NN  hl ">parts</span>{" "}
                <span className="readsyon readsyNon  fnt">of</span>{" "}
                <span className="readsyon readsyKey NN  hl ">speech</span>{" "}
                <span className="readsyon readsyNon  hl">(such</span>{" "}
                <span className="readsyon readsyNon  fnt">as</span>{" "}
                <span className="readsyon readsyKey NN  hl ">dates</span>{" "}
                <span className="readsyon readsyNon  fnt">and</span>{" "}
                <span className="readsyon readsyKey NN  hl ">numbers)</span>{" "}
                <span className="readsyon readsyNon  fnt">to</span>{" "}
                <span className="readsyon readsyKey VB  hl ">make</span>{" "}
                <span className="readsyon readsyKey JJ  hl ">specific</span>{" "}
                <span className="readsyon readsyKey NN  hl ">details</span>{" "}
                <span className="readsyon readsyKey NN  hl ">jump</span>{" "}
                <span className="readsyon readsyNon  fnt">out.</span>
              </p>
              <p>
                <span className="readsyon readsyNon  fnt">The</span>{" "}
                <span className="readsyon readsyKey JJ  hl ">best</span>{" "}
                <span className="readsyon readsyKey NN  hl ">way</span>{" "}
                <span className="readsyon readsyNon  fnt">to</span>{" "}
                <span className="readsyon readsyKey VB  hl ">understand</span>{" "}
                <span className="readsyon readsyProp  hl">Readsy</span>{" "}
                <span className="readsyon readsyKey VB  hl ">is</span>{" "}
                <span className="readsyon readsyNon  fnt">to</span>{" "}
                <span className="readsyon readsyKey VB  hl ">give</span>{" "}
                <span className="readsyon readsyNon  fnt">it</span>{" "}
                <span className="readsyon readsyNon  fnt">a</span>{" "}
                <span className="readsyon readsyKey NN  hl ">try.</span>{" "}
                <span className="readsyon readsyProp  hl">Sign</span>{" "}
                <span className="readsyon readsyNon  fnt">up</span>{" "}
                <span className="readsyon readsyKey NN  hl ">today!</span>{" "}

              </p>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-8 mt-8 xl:mt-12 xl:gap-12 md:grid-cols-1 xl:grid-cols-1">
          <div className="text-left p-8 space-y-3 border-2 border-blue-400 dark:border-blue-300 rounded-xl">
          <div><h2 className="text-xl font-semibold text-gray-800 capitalize lg:text-xl dark:text-white">
            Fade Less Important Words
          </h2></div>
              <p>
                <span className="readsyon textreadsyProp  hl">Readsy</span>{" "}
                <span className="readsyon textreadsyKey VB  hl ">highlights</span>{" "}
                <span className="readsyon textreadsyKey JJ  hl ">key</span>{" "}
                <span className="readsyon textreadsyKey NN  hl ">text</span>{" "}
                <span className="readsyon textreadsyNon  fnt">on</span>{" "}
                <span className="readsyon textreadsyKey NN  hl ">webpages</span>{" "}
                <span className="readsyon textreadsyNon  fnt">to</span>{" "}
                <span className="readsyon textreadsyKey VB  hl ">help</span>{" "}
                <span className="readsyon textreadsyKey NN  hl ">users</span>{" "}
                <span className="readsyon textreadsyKey VB  hl ">consume</span>{" "}
                <span className="readsyon textreadsyKey NN  hl ">information</span>{" "}
                <span className="readsyon textreadsyKey JJ  hl ">more</span>{" "}
                <span className="readsyon textreadsyNon  fnt">easily.</span>{" "}
                <span className="readsyon textreadsyNon  fnt">The</span>{" "}
                <span className="readsyon textreadsyKey NN  hl ">browser</span>{" "}
                <span className="readsyon textreadsyKey NN  hl ">extension</span>{" "}
                <span className="readsyon textreadsyKey VB  hl ">analyzes</span>{" "}
                <span className="readsyon textreadsyKey NN  hl ">sentence</span>{" "}
                <span className="readsyon textreadsyKey NN  hl ">
                  parts-of-speech
                </span>{" "}
                <span className="readsyon textreadsyNon  fnt">and</span>{" "}
                <span className="readsyon textreadsyKey VB  hl ">adds</span>{" "}
                <span className="readsyon textreadsyKey NN  hl ">customizable</span>{" "}
                <span className="readsyon textreadsyKey VB  hl ">formatting</span>{" "}
                <span className="readsyon textreadsyNon  fnt">around</span>{" "}
                <span className="readsyon textreadsyNon  fnt">each</span>{" "}
                <span className="readsyon textreadsyKey NN  hl ">item.</span>{" "}
                <span className="readsyon textreadsyNon  fnt">This</span>{" "}
                <span className="readsyon textreadsyKey VB  hl ">allows</span>{" "}
                <span className="readsyon textreadsyKey NN  hl ">users</span>{" "}
                <span className="readsyon textreadsyNon  fnt">to</span>{" "}
                <span className="readsyon textreadsyKey VB  hl ">skip</span>{" "}
                <span className="readsyon textreadsyNon  fnt">over</span>{" "}
                <span className="readsyon textreadsyKey NN  hl ">text</span>{" "}
                <span className="readsyon textreadsyNon  fnt">like</span>{" "}
                <span className="readsyon textreadsyKey NN  hl ">prepositions</span>{" "}
                <span className="readsyon textreadsyNon  fnt">and</span>{" "}
                <span className="readsyon textreadsyKey NN  hl ">
                  conjunctions,
                </span>{" "}
                <span className="readsyon textreadsyNon  fnt">and</span>{" "}
                <span className="readsyon textreadsyKey NN  hl ">focus</span>{" "}
                <span className="readsyon textreadsyNon  fnt">on</span>{" "}
                <span className="readsyon textreadsyKey NN  hl ">words</span>{" "}
                <span className="readsyon textreadsyKey JJ  hl ">necessary</span>{" "}
                <span className="readsyon textreadsyNon  fnt">for</span>{" "}
                <span className="readsyon textreadsyKey JJ  hl ">general</span>{" "}
                <span className="readsyon textreadsyKey NN  hl ">
                  comprehension
                </span>{" "}
                <span className="readsyon textreadsyKey NN  hl">(nouns,</span>{" "}
                <span className="readsyon textreadsyKey NN  hl ">verbs,</span>{" "}
                <span className="readsyon textreadsyNon  fnt">and</span>{" "}
                <span className="readsyon textreadsyKey NN  hl ">adjectives).</span>{" "}
                <span className="readsyon textreadsyKey VB  hl">Give</span>{" "}
                <span className="readsyon textreadsyNon  fnt">it</span>{" "}
                <span className="readsyon textreadsyNon  fnt">a</span>{" "}
                <span className="readsyon textreadsyKey VB  hl ">try!</span>{" "}
                <span className="readsyon textreadsyKey VB  hl ">Try</span>{" "}
                <span className="readsyon textreadsyKey VB  hl ">focusing</span>{" "}
                <span className="readsyon textreadsyNon  fnt">on</span>{" "}
                <span className="readsyon textreadsyNon  fnt">the</span>{" "}
                <span className="readsyon textreadsyKey VB  hl ">highlighted</span>{" "}
                <span className="readsyon textreadsyKey NN  hl ">words</span>{" "}
                <span className="readsyon textreadsyNon  fnt">and</span>{" "}
                <span className="readsyon textreadsyKey VB  hl ">see</span>{" "}
                <span className="readsyon textreadsyNon  fnt">how</span>{" "}
                <span className="readsyon textreadsyNon  fnt">fast</span>{" "}
                <span className="readsyon textreadsyNon  fnt">you</span>{" "}
                <span className="readsyon textreadsyNon  fnt">can</span>{" "}
                <span className="readsyon textreadsyKey VB  hl ">read.</span>
              </p>
              <p>
                <span className="readsyon textreadsyNon  fnt">The</span>{" "}
                <span className="readsyon textreadsyKey NN  hl ">fact</span>{" "}
                <span className="readsyon textreadsyKey VB  hl ">is,</span>{" "}
                <span className="readsyon textreadsyKey JJ  hl ">many</span>{" "}
                <span className="readsyon textreadsyKey NN  hl ">people</span>{" "}
                <span className="readsyon textreadsyKey VB  hl ">skim</span>{" "}
                <span className="readsyon textreadsyKey NN  hl ">content</span>{" "}
                <span className="readsyon textreadsyNon  fnt">when</span>{" "}
                <span className="readsyon textreadsyNon  fnt">they</span>{" "}
                <span className="readsyon textreadsyKey VB  hl ">read,</span>{" "}
                <span className="readsyon textreadsyNon  fnt">or</span>{" "}
                <span className="readsyon textreadsyNon  fnt">they</span>{" "}
                <span className="readsyon textreadsyNon  fnt">simply</span>{" "}
                <span className="readsyon textreadsyKey VB  hl ">read</span>{" "}
                <span className="readsyon textreadsyNon  fnt">the</span>{" "}
                <span className="readsyon textreadsyNum  hl">first</span>{" "}
                <span className="readsyon textreadsyKey NN  hl ">paragraph</span>{" "}
                <span className="readsyon textreadsyNon  fnt">of</span>{" "}
                <span className="readsyon textreadsyKey JJ  hl ">online</span>{" "}
                <span className="readsyon textreadsyKey NN  hl ">articles</span>{" "}
                <span className="readsyon textreadsyNon  fnt">and</span>{" "}
                <span className="readsyon textreadsyKey NN  hl ">move</span>{" "}
                <span className="readsyon textreadsyNon  fnt">on.</span>{" "}
                <span className="readsyon textreadsyProp  hl">Readsy</span>{" "}
                <span className="readsyon textreadsyKey VB  hl ">is</span>{" "}
                <span className="readsyon textreadsyKey VB  hl ">aimed</span>{" "}
                <span className="readsyon textreadsyNon  fnt">at</span>{" "}
                <span className="readsyon textreadsyKey VB  hl ">helping</span>{" "}
                <span className="readsyon textreadsyNon  fnt">those</span>{" "}
                <span className="readsyon textreadsyKey NN  hl ">people</span>{" "}
                <span className="readsyon textreadsyKey VB  hl ">read</span>{" "}
                <span className="readsyon textreadsyKey OT  hl ">faster,</span>{" "}
                <span className="readsyon textreadsyKey VB  hl ">read</span>{" "}
                <span className="readsyon textreadsyNon  fnt">longer</span>{" "}
                <span className="readsyon textreadsyNon  fnt">and</span>{" "}
                <span className="readsyon textreadsyKey VB  hl ">retain</span>{" "}
                <span className="readsyon textreadsyKey JJ  hl ">more</span>{" "}
                <span className="readsyon textreadsyNon  fnt">than</span>{" "}
                <span className="readsyon textreadsyNon  fnt">they</span>{" "}
                <span className="readsyon textreadsyNon  fnt">otherwise</span>{" "}
                <span className="readsyon textreadsyNon  fnt">might.</span>{" "}
                <span className="readsyon textreadsyNon  fnt">It's</span>{" "}
                <span className="readsyon textreadsyNon  fnt">not</span>{" "}
                <span className="readsyon textreadsyKey VB  hl ">intended</span>{" "}
                <span className="readsyon textreadsyNon  fnt">for</span>{" "}
                <span className="readsyon textreadsyNon  fnt">all</span>{" "}
                <span className="readsyon textreadsyKey NN  hl ">readers</span>{" "}
                <span className="readsyon textreadsyNon  fnt">or</span>{" "}
                <span className="readsyon textreadsyNon  fnt">all</span>{" "}
                <span className="readsyon textreadsyKey NN  hl ">types</span>{" "}
                <span className="readsyon textreadsyNon  fnt">of</span>{" "}
                <span className="readsyon textreadsyKey NN  hl ">content,</span>{" "}
                <span className="readsyon textreadsyNon  fnt">but</span>{" "}
                <span className="readsyon textreadsyNon  fnt">the</span>{" "}
                <span className="readsyon textreadsyNon  fnt">side-by-side</span>{" "}
                <span className="readsyon textreadsyKey NN  hl ">comparison</span>{" "}
                <span className="readsyon textreadsyKey VB  hl ">is</span>{" "}
                <span className="readsyon textreadsyKey JJ  hl ">compelling.</span>
              </p>
              <p>
                <span className="readsyon textreadsyProp  hl">Readsy</span>{" "}
                <span className="readsyon textreadsyNon  fnt">can</span>{" "}
                <span className="readsyon textreadsyNon  fnt">easily</span>{" "}
                <span className="readsyon textreadsyKey VB  hl ">be</span>{" "}
                <span className="readsyon textreadsyKey VB  hl ">toggled</span>{" "}
                <span className="readsyon textreadsyNon  fnt">on/off,</span>{" "}
                <span className="readsyon textreadsyNon  fnt">and</span>{" "}
                <span className="readsyon textreadsyNon  fnt">the</span>{" "}
                <span className="readsyon textreadsyKey NN  hl ">colors</span>{" "}
                <span className="readsyon textreadsyKey VB  hl ">are</span>{" "}
                <span className="readsyon textreadsyNon  fnt">fully</span>{" "}
                <span className="readsyon textreadsyKey NN  hl ">
                  customizable.
                </span>{" "}
                <span className="readsyon textreadsyNon  fnt">You</span>{" "}
                <span className="readsyon textreadsyNon  fnt">can</span>{" "}
                <span className="readsyon textreadsyNon  fnt">even</span>{" "}
                <span className="readsyon textreadsyKey VB  hl ">turn</span>{" "}
                <span className="readsyon textreadsyNon  fnt">on</span>{" "}
                <span className="readsyon textreadsyKey VB  hl ">highlights</span>{" "}
                <span className="readsyon textreadsyNon  fnt">just</span>{" "}
                <span className="readsyon textreadsyNon  fnt">for</span>{" "}
                <span className="readsyon textreadsyKey JJ  hl ">certain</span>{" "}
                <span className="readsyon textreadsyKey NN  hl ">parts</span>{" "}
                <span className="readsyon textreadsyNon  fnt">of</span>{" "}
                <span className="readsyon textreadsyKey NN  hl ">speech</span>{" "}
                <span className="readsyon textreadsyNon  hl">(such</span>{" "}
                <span className="readsyon textreadsyNon  fnt">as</span>{" "}
                <span className="readsyon textreadsyKey NN  hl ">dates</span>{" "}
                <span className="readsyon textreadsyNon  fnt">and</span>{" "}
                <span className="readsyon textreadsyKey NN  hl ">numbers)</span>{" "}
                <span className="readsyon textreadsyNon  fnt">to</span>{" "}
                <span className="readsyon textreadsyKey VB  hl ">make</span>{" "}
                <span className="readsyon textreadsyKey JJ  hl ">specific</span>{" "}
                <span className="readsyon textreadsyKey NN  hl ">details</span>{" "}
                <span className="readsyon textreadsyKey NN  hl ">jump</span>{" "}
                <span className="readsyon textreadsyNon  fnt">out.</span>
              </p>
              <p>
                <span className="readsyon textreadsyNon  fnt">The</span>{" "}
                <span className="readsyon textreadsyKey JJ  hl ">best</span>{" "}
                <span className="readsyon textreadsyKey NN  hl ">way</span>{" "}
                <span className="readsyon textreadsyNon  fnt">to</span>{" "}
                <span className="readsyon textreadsyKey VB  hl ">understand</span>{" "}
                <span className="readsyon textreadsyProp  hl">Readsy</span>{" "}
                <span className="readsyon textreadsyKey VB  hl ">is</span>{" "}
                <span className="readsyon textreadsyNon  fnt">to</span>{" "}
                <span className="readsyon textreadsyKey VB  hl ">give</span>{" "}
                <span className="readsyon textreadsyNon  fnt">it</span>{" "}
                <span className="readsyon textreadsyNon  fnt">a</span>{" "}
                <span className="readsyon textreadsyKey NN  hl ">try.</span>{" "}
                <span className="readsyon textreadsyProp  hl">Sign</span>{" "}
                <span className="readsyon textreadsyNon  fnt">up</span>{" "}
                <span className="readsyon textreadsyKey NN  hl ">today!</span>{" "}

              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-gray-100 dark:bg-gray-900 lg:py-12 lg:flex lg:justify-center">
        <div className="bg-white dark:bg-gray-800 lg:mx-8 lg:flex lg:max-w-5xl lg:shadow-lg lg:rounded-lg">
          <div className="hidden lg:flex lg:w-1/2">
            <div className="h-64 bg-cover lg:rounded-lg lg:h-full">
              <img
                className="w-[28rem] h-[14rem] flex-shrink-0 object-cover xl:w-[34rem] xl:h-[17rem]"
                src={downloadImg}
                alt="Readsy screenshot example"
              />
            </div>
          </div>

          <div className="max-w-xl px-6 py-12 lg:max-w-5xl lg:w-1/2">
            <h2 className="text-2xl font-bold text-gray-800 dark:text-white md:text-3xl">
              Get Started{" "}
              <span className="text-blue-600 dark:text-blue-400">Today</span>
            </h2>
            <p className="mt-4 text-gray-600 dark:text-gray-400">
              Use the links below to begin. You'll need a free Readsy account
              and one of our browser extensions to start Readsy-ing.
            </p>

            <div className="mt-8">
              {!authUser ? (
                <a
                  href="/register"
                  className="px-5 mx-2 py-2 font-semibold text-white transition-colors duration-200 transform bg-blue-600 rounded-md hover:bg-blue-400 focus:outline-none focus:bg-blue-400"
                >
                  Sign Up
                </a>
              ) : (
                <></>
              )}

              <a
                href="/extensions"
                className="px-5 mx-2 py-2 font-semibold text-white transition-colors duration-200 transform bg-blue-600 rounded-md hover:bg-blue-400 focus:outline-none focus:bg-blue-400"
              >
                Get the Extension
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
