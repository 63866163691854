import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Joi from "joi-browser";
import { toast } from "react-toastify";

import PageTitle from "../common/pageTitle";
import auth from "../../services/authService";
import useAuth from "../../hooks/useAuth";
import { validate } from "../../services/validationService";
import { inputFormat, normalSubmitButtonStyle } from "../common/styleClasses";

const RegisterForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const { setAuth } = useAuth();
  const [requestSent, setRequestSent] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConf, setPasswordConf] = useState("");
  const [emailState, setEmailState] = useState(true);
  const [tosState, setTosState] = useState(false);
  const [privacyState, setPrivacyState] = useState(false);

  const [errors, setErrors] = useState({});

  const schema = {
    firstName: Joi.string().required().min(1).max(100).label("First Name"),
    lastName: Joi.string().required().min(1).max(100).label("Last Name"),
    username: Joi.string().required().min(1).max(100).label("Username"),
    password: Joi.string().required().min(1).max(100).label("Password"),
  };

  useEffect(() => {
    if (auth.getCurrentUser()) window.location = "/home";
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== passwordConf && 1 === 0) {
      toast.error("Passwords do not match.");
    } else if (1===0 && (!tosState || !privacyState)) {
      toast.error(
        "Please accept the Terms-of-Service and Privacy Policy after reviewing both"
      );
    } else if (
      !validate(
        {
          firstName,
          lastName,
          username,
          password,
        },
        schema
      )
    ) {
      try {
        setRequestSent(true);
        const registrationConfirmation = await auth.register({
          firstName,
          lastName,
          username,
          password,
          emailState,
        });
        await auth.login(username, password);
        const currentUser = auth.getCurrentUser();

        const accessToken = localStorage.getItem("readsyToken");
        const roles = currentUser.roles;
        setAuth({ ...currentUser, accessToken });

        toast.success(`Login successful, Welcome ${currentUser.firstName}`);
        navigate("/home", { replace: true });
      } catch (error) {
        if (error.response && error.response.status === 400) {
          toast.error(error.response.data);
          if (error.response.data === "User already registered.") {
            navigate("/login", { replace: true });
          }
        }
      }
    }
  };

  return (
    <React.Fragment>
      <PageTitle pageTitle="Readsy - Register" />
      {requestSent ? (
        <>
          <h1 className="text-2xl mt-6 font-semibold text-gray-700 capitalize dark:text-white">
            Welcome to Readsy!{" "}
            <a
              href="/home"
              className="mx-2 text-sm font-bold text-blue-500 dark:text-blue-400 hover:underline"
            >
              Go Home
            </a>
          </h1>
        </>
      ) : (
        <div className="bg-white dark:bg-gray-900 mx-auto content-center my-16">
          <div className="w-full max-w-sm mx-auto overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800">
            <h1 className="text-2xl font-semibold text-gray-700 capitalize dark:text-white">
              Setup an account for future advanced features </h1>
              <h2 className="text-md font-semibold text-gray-400 capitalize dark:text-white">(Readsy can be used without an account)
            </h2>
            <div className="px-6 py-4">
              <form onSubmit={handleSubmit}>
                <div className="w-full mt-4">
                  <input
                    className={inputFormat}
                    type="firstName"
                    placeholder="First Name"
                    aria-label="First Name"
                    onChange={(e) => setFirstName(e.target.value)}
                    value={firstName}
                    required
                    autoComplete="off"
                    error={errors["firstName"]}
                  />
                </div>
                <div className="w-full mt-4">
                  <input
                    className={inputFormat}
                    type="lastName"
                    placeholder="Last Name"
                    aria-label="Last Name"
                    onChange={(e) => setLastName(e.target.value)}
                    value={lastName}
                    required
                    autoComplete="off"
                    error={errors["lastName"]}
                  />
                </div>
                <div className="w-full mt-4">
                  <input
                    className={inputFormat}
                    type="username"
                    placeholder="Email"
                    aria-label="Email"
                    onChange={(e) => setUsername(e.target.value)}
                    value={username}
                    required
                    autoComplete="off"
                    error={errors["username"]}
                  />
                </div>
                <div className="w-full mt-4">
                  <input
                    className={inputFormat}
                    type="password"
                    placeholder="Password"
                    aria-label="Password"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    required
                    autoComplete="off"
                    error={errors["password"]}
                  />
                </div>

                <div className="flex items-center justify-center py-4 text-center dark:bg-gray-700">

                    <input
                      type="checkbox"
                      checked={emailState}
                      onChange={() => setEmailState(!emailState)}
                    />

                  <span className="text-sm text-gray-600 dark:text-gray-200">
                    Sign me up for email notifications regarding updates and
                    other news
                  </span>
                </div>
                {/* <div className="flex items-center justify-center py-4 text-center dark:bg-gray-700">

                    <input
                      type="checkbox"
                      checked={tosState}
                      onChange={() => setTosState(!tosState)}
                    />

                  <span className="text-sm text-gray-600 dark:text-gray-200">
                    Please read and accept the Terms-of-Service{" "}
                  </span>

                  <a
                    href="/tos.html"
                    download
                    className="mx-2 text-sm font-bold text-blue-500 dark:text-blue-400 hover:no-underline"
                  >
                    Click Here to View
                  </a>
                </div> */}
                {/* <div className="flex items-center justify-center py-4 text-center dark:bg-gray-700">

                    <input
                      type="checkbox"
                      checked={privacyState}
                      onChange={() => setPrivacyState(!privacyState)}
                    />

                  <span className="text-sm text-gray-600 dark:text-gray-200">
                    Please read and acknowledge your consent of the Privacy
                    Policy{" "}
                  </span>
        
                  <a
                    href="/ReadsyPrivacyPolicy.html"
                    download
                    className="mx-2 text-sm font-bold text-blue-500 dark:text-blue-400 hover:no-underline"
                  >
                    Click Here to View
                  </a>
                </div> */}

                <div className="flex items-center justify-between mt-4">
                  <div className="flex items-center justify-center py-4 text-center dark:bg-gray-700">
                    <span className="text-sm text-gray-600 dark:text-gray-200">
                      Already have an account?{" "}
                    </span>

                    <a
                      href="/login"
                      className="mx-2 text-sm font-bold text-blue-500 dark:text-blue-400 hover:no-underline"
                    >
                      Login
                    </a>
                  </div>
                  <button
                    className={normalSubmitButtonStyle}
                    type="submit"
                    disabled={validate(
                      {
                        firstName,
                        lastName,
                        username,
                        password,
                      },
                      schema
                    )}
                  >
                    Register
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default RegisterForm;
