import React from "react";
import PageTitle from "./common/pageTitle";
import { CheckCircleIcon } from "@heroicons/react/solid";
const logo = require("../media/aboutReadingImage.jpg");
const Premium = () => {
  return (
    <>
      <PageTitle pageTitle="Readsy - Premium Tier" />

      <div className="bg-white dark:bg-gray-900">
        <div className="container px-6 py-8 mx-auto">
          <div className="">
            <div className="flex flex-col items-center xl:mx-8 w-full">
              <h1 className="text-3xl font-medium text-gray-800 capitalize lg:text-4xl dark:text-white">
                Upgrade To Premium, Cancel Anytime
              </h1>

              <div className="mt-4">
                <span className="inline-block w-40 h-1 bg-blue-500 rounded-full"></span>
              </div>

              <p className="my-4 font-medium text-gray-500 dark:text-gray-300">
                Try Readsy for free or go premium for unlimited articles and
                advanced features with payments processed via Stripe for
                security and peace of mind
              </p>
            </div>

            <div className="flex-1 xl:mx-8">
              <div className="justify-evenly mt-8 space-y-8 md:-mx-4 md:flex md:justify-evenly md:space-y-0 xl:mt-0">
                <div className=" w-1/3 mx-auto border rounded-lg md:mx-4 dark:border-gray-700">
                  <div className="p-6">
                    <h1 className="text-xl font-medium text-gray-700 capitalize lg:text-3xl dark:text-white">
                      Basic
                    </h1>

                    <p className="mt-4 text-gray-500 dark:text-gray-300">
                      A no-risk opportunity to see if Readsy is right for you,
                      with upgrades available anytime
                    </p>

                    <h2 className="mt-4 text-2xl font-medium text-gray-700 sm:text-4xl dark:text-gray-300">
                      $0 <span className="text-base font-medium">/Month</span>
                    </h2>

                    <p className="mt-1 text-gray-500 dark:text-gray-300">
                      It's Free!{" "}
                    </p>

                    <button className="w-full px-4 py-2 mt-6 tracking-wide text-white capitalize transition-colors duration-200 transform bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:bg-blue-500 focus:ring focus:ring-blue-300 focus:ring-opacity-80">
                      Start Now
                    </button>
                    {/* <a
                      href="https://chrome.google.com/webstore/detail/readalyze/nopgbkabhipfhbciolfjhmkflphjfkca?hl=en&authuser=0"
                      className="w-full px-4 py-2 mt-6 tracking-wide text-white capitalize transition-colors duration-200 transform bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:bg-blue-500 focus:ring focus:ring-blue-300 focus:ring-opacity-80"
                    >
                      Start Now
                    </a> */}
                  </div>

                  <hr className="border-gray-200 dark:border-gray-700" />

                  <div className="p-6">
                    <h1 className="text-lg font-medium text-gray-700 capitalize lg:text-xl dark:text-white">
                      What’s included:
                    </h1>

                    <div className="mt-8 space-y-4">
                      <div className="flex items-center">
                        <div className="w-1/6">
                          <CheckCircleIcon
                            className="h-5 w-5 text-blue-500"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          />
                        </div>

                        <span className="mx-4 text-gray-700 dark:text-gray-300 text-left">
                          Fully customizable formatting
                        </span>
                      </div>

                      <div className="flex items-center">
                        <div className="w-1/6">
                          <CheckCircleIcon
                            className="h-5 w-5 text-blue-500"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          />
                        </div>

                        <span className="mx-4 text-gray-700 dark:text-gray-300 text-left">
                          Use Readsy 15 times per month
                        </span>
                      </div>

                      <div className="flex items-center">
                        <div className="w-1/6">
                          <CheckCircleIcon
                            className="h-5 w-5 text-blue-500"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          />
                        </div>

                        <span className="mx-4 text-gray-700 dark:text-gray-300 text-left">
                          Easy toggle on/off
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" w-1/3 mx-auto border rounded-lg md:mx-4 dark:border-gray-700">
                  <div className="p-6">
                    <h1 className="text-xl font-medium text-gray-700 capitalize lg:text-3xl dark:text-white">
                      Premium
                    </h1>

                    <p className="mt-4 text-gray-500 dark:text-gray-300">
                      Don't want to be Basic? Join our Premium tier and
                      experience advanced features
                    </p>

                    <h2 className="mt-4 text-2xl font-medium text-gray-700 sm:text-4xl dark:text-gray-300">
                      $2 <span className="text-base font-medium">/Month</span>
                    </h2>

                    <p className="mt-1 text-gray-500 dark:text-gray-300">
                      Yearly payment
                    </p>

                    <button className="w-full px-4 py-2 mt-6 tracking-wide text-white capitalize transition-colors duration-200 transform bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:bg-blue-500 focus:ring focus:ring-blue-300 focus:ring-opacity-80">
                      Start Now
                    </button>
                  </div>

                  <hr className="border-gray-200 dark:border-gray-700" />

                  <div className="p-6">
                    <h1 className="text-lg font-medium text-gray-700 capitalize lg:text-xl dark:text-white">
                      What’s included:
                    </h1>

                    <div className="mt-8 space-y-4">
                      <div className="flex items-center">
                        <div className="w-1/6">
                          <CheckCircleIcon
                            className="h-5 w-5 text-blue-500"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          />
                        </div>

                        <span className="mx-4 text-gray-700 dark:text-gray-300 text-left">
                          All features of Basic
                        </span>
                      </div>

                      <div className="flex items-center">
                        <div className="w-1/6">
                          <CheckCircleIcon
                            className="h-5 w-5 text-blue-500"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          />
                        </div>
                        <span className="mx-4 text-gray-700 dark:text-gray-300 text-left">
                          Unlimited articles per month
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Premium;
