import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserIcon, MenuIcon, XIcon } from "@heroicons/react/outline";
import { toast } from "react-toastify";
import AuthContext from "./authProvider";
import PageTitle from "./common/pageTitle";
import { deleteUserAccount } from "../services/userService";

const UserAccount = () => {
  const { auth: authUser, setAuth } = useContext(AuthContext);
  const deleteAccount = () => {
    const submitData = async (input) => {
      try {
        const resetResult = await deleteUserAccount({
          token: authUser._id,
        });

        const timer = setTimeout(() => {
          window.location = "/home";
        }, 1000);
      } catch (error) {
        if (error.response && error.response.status === 400) {
          toast.error(error.response.data);
        }
      }
    };
    submitData();
  };
  return (
    <>
      <PageTitle pageTitle="Readsy - User Account" />
      <section>
        <h2 className="text-xl font-semibold text-gray-800 capitalize lg:text-xl dark:text-white">Account Details</h2>
        <div>
          <div>
            First Name: <span>{authUser.firstName}</span>
          </div>
          <div>
            Last Name: <span>{authUser.lastName}</span>
          </div>
          <div>
            Email: <span>{authUser.email}</span>
          </div>
          {/* <div>
            Account Type: <span>{authUser.licenseType}</span>
          </div>
          {authUser.licenseType !== "premium" ? (
            ""
          ) : (
            <div>
              Premium Expiration:{" "}
              <span>{authUser.licenseExpiration.substring(0, 10)}</span>
            </div>
          )} */}
          <hr />
          <button
            className="px-5 mx-2 py-2 font-semibold text-white transition-colors duration-200 transform bg-red-600 rounded-md hover:bg-red-400 focus:outline-none focus:bg-red-400"
            onClick={() => {
              if (
                window.confirm(
                  "Are you sure you wish to delete your account? This is irreversible."
                )
              )
                deleteAccount();
            }}
          >
            Delete Account
          </button>
        </div>
        <div className="flexGrow"></div>
      </section>
    </>
  );
};

export default UserAccount;
