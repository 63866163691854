import { useEffect } from "react";
import auth from "../../services/authService";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../authProvider";

export const ConfirmAccount = () => {
  const { setAuth } = useContext(AuthContext);
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/home");
  });
  return null;
};

export default ConfirmAccount;
