import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import PageTitle from "../common/pageTitle";
import auth from "../../services/authService";
import useAuth from "../../hooks/useAuth";

const ConnectExtension = () => {
  useEffect(() => {
    auth.loginWithoutJwt();
    if (!auth.getCurrentUser()) {
      window.location = "/login";
    }
  }, []);

  return (
    <React.Fragment>
      <PageTitle pageTitle="Readsy - Connect Extension" />
      <div className="bg-white dark:bg-gray-900 mx-auto content-center my-16">
        <div className="w-full max-w-sm mx-auto overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800">
          <h1 className="text-2xl font-semibold text-gray-700 capitalize dark:text-white">
            Thanks for confirming your account, please refresh your browser and
            you'll be ready to Readsy!
          </h1>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ConnectExtension;
