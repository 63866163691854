import http from "./httpService";
import config from "../config.json";

export function contactUsSend(msg) {
  try {
    return http.post(config.apiEndpoint + "/comm/contact-us", {
      name: msg.name,
      email: msg.email,
      message: msg.message,
    });
  } catch (err) {
    return err;
  }
}

export default {
  contactUsSend,
};
