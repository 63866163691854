import React from "react";
import ReactDOM from "react-dom";

import { ToastContainer } from "react-toastify";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./components/authProvider";
import HttpsRedirect from "react-https-redirect";

import "./index.css";
import App from "./App";

// import your route components too
const rootElement = document.getElementById("root");
ReactDOM.render(
  <HttpsRedirect>
    <React.StrictMode>
      <ToastContainer pauseOnFocusLoss={false} />
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route path="/*" element={<App />} />
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </React.StrictMode>
  </HttpsRedirect>,

  rootElement
);
