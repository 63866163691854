import React from "react";
import { PlusIcon, MinusIcon } from "@heroicons/react/outline";
const Accordion = ({ title, children }) => {
  const [isOpen, setOpen] = React.useState(false);

  return (
    <div className="accordion-wrapper align-items">
      <div
        className={`accordion-title items-center px-4 mx-4 text-xl text-gray-700 dark:text-white ${
          isOpen ? "open" : ""
        }`}
        onClick={() => setOpen(!isOpen)}
      >
        {!isOpen ? (
          <PlusIcon className="h-4 w-4 mx-4" />
        ) : (
          <MinusIcon className="h-4 w-4 mx-4" />
        )}
        {title}
      </div>
      <div
        className={`accordion-item flex mt-8 md:mx-10 ${
          !isOpen ? "collapsed" : ""
        }`}
      >
        {/* <span className="border border-blue-500"></span> */}
        <div className="accordion-content text-left mx-8 max-w-3xl px-4 text-gray-500 dark:text-gray-300">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Accordion;
