import React, { useState } from "react";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import PageTitle from "../common/pageTitle";
import { validate } from "../../services/validationService";
import { resetPwRequest } from "../../services/userService";

const ForgotPasswordForm = () => {
  const [username, setUsername] = useState("");
  const [errors, setErrors] = useState({});
  const schema = {
    username: Joi.string()
      .required()
      .email({ minDomainSegments: 2 })
      .label("Username"),
  };
  const handleSubmit = async (e) => {
    const validationResult = validate({ username }, schema);

    e.preventDefault();
    if (!validationResult) {
      try {
        const passwordResetResult = await resetPwRequest(username);

        if (passwordResetResult.data === "success") {
          toast.success(
            `Attempting to send password reset email to ${username}`
          );
          const timer = setTimeout(() => {
            // window.location = "/";
          }, 1000);
        } else {
          toast.error(
            "Error sending password reset email, please check email provided and try again"
          );
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          toast.error(error.response.data);
        }
      }
    } else {
      toast.error(validationResult["username"]);
    }
  };
  const inputFormat =
    "block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-500 bg-white border rounded-md dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 focus:border-blue-400 dark:focus:border-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring focus:ring-blue-300";

  return (
    <React.Fragment>
      <PageTitle pageTitle="Readsy - Forgot Password" />

      <section className="bg-white dark:bg-gray-800">
        <div className="container px-6 py-4 mx-auto text-center">
          <div className="max-w-lg mx-auto">
            <h1 className="text-3xl font-bold text-gray-800 dark:text-white md:text-4xl">
              Noooooooo! Forgetting passwords is the worst.
            </h1>

            <p className="mt-6 text-gray-500 dark:text-gray-300">
              You know the drill. Give us your email and we'll send you a link
              to reset your code.
            </p>
            <div className="mt-2">
              <span className="inline-block w-40 h-1 rounded-full bg-blue-500"></span>
            </div>

            <div className="bg-white dark:bg-gray-900 mx-auto content-center">
              <div className="w-full max-w-sm mx-auto overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800">
                <div className="px-6 py-4">
                  <form onSubmit={handleSubmit}>
                    <div className="w-full mt-4">
                      <input
                        className={inputFormat}
                        type="username"
                        placeholder="Email"
                        aria-label="Email"
                        onChange={(e) => setUsername(e.target.value)}
                        value={username}
                        required
                        autoComplete="on"
                        error={errors["username"]}
                      />
                    </div>

                    <div className="flex items-center justify-between mt-4">
                      <button
                        className="px-4 py-2 leading-5 text-white transition-colors duration-200 transform bg-blue-500 rounded-md hover:bg-blue-400 focus:outline-none focus:bg-blue-400"
                        type="submit"
                        //   disabled={validate({ username }, schema)}
                      >
                        Send Reset Email
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div className="max-w-screen-xl mx-auto mt-20">
            <div className="grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-5"></div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default ForgotPasswordForm;
