import http from "./httpService";
import config from "../config.json";

export function register(user) {
  return http.post(config.apiEndpoint + "/users/register", {
    email: user.username,
    password: user.password,
    firstName: user.firstName,
    lastName: user.lastName,
  });
}

export function signup(user) {
  return http.post(config.apiEndpoint + "/users/signup", {
    email: user.username,
  });
}

export function resetPwRequest(user) {
  return http.post(config.apiEndpoint + "/users/forgot-password", {
    email: user,
  });
}

export function resetPw(resetData) {
  console.log(resetData);
  return http.post(config.apiEndpoint + "/users/reset-password", {
    email: resetData.username,
    password: resetData.password,
    passwordConfirm: resetData.passwordConfirm,
    token: resetData.token,
  });
}

export function confirmResetToken(token) {
  return http.post(config.apiEndpoint + "/users/confirm-reset-token", {
    token,
  });
}

export function confirmAccount(token) {
  return http.post(config.apiEndpoint + "/users/confirm-account", {
    token,
  });
}

export function deleteUserAccount(submitData) {
  return http.post(config.apiEndpoint + "/users/delete-account", {
    token: submitData.token,
  });
}
