import React, { useState, useEffect } from "react";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

import PageTitle from "../common/pageTitle";
import { validate } from "../../services/validationService";
import { confirmResetToken, resetPw } from "../../services/userService";

const ResetPasswordForm = () => {
  let { id } = useParams();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [errors, setErrors] = useState({});

  const schema = {
    username: Joi.string()
      .required()
      .email({ minDomainSegments: 2 })
      .label("Username"),
    password: Joi.string().required().label("Password"),
    passwordConfirm: Joi.string().required().label("Password"),
  };
  useEffect(() => {
    const fetchData = async (input) => {
      try {
        const fetchResult = await confirmResetToken(input);
        setUsername(fetchResult.data.email);
      } catch (error) {
        setErrors({ invalidToken: true });
      }
    };
    fetchData(id);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== passwordConfirm) {
      toast.error("Password entries do not match");
      return;
    }
    const submitData = async (input) => {
      if (!validate({ username, password, passwordConfirm }, schema)) {
        try {
          const resetResult = await resetPw({
            username,
            password,
            passwordConfirm,
            token: input,
          });

          const timer = setTimeout(() => {
            window.location = "/login";
          }, 1000);
        } catch (error) {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data);
          }
        }
      }
    };
    submitData(id);
  };
  const inputFormat =
    "block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-500 bg-white border rounded-md dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 focus:border-blue-400 dark:focus:border-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring focus:ring-blue-300";

  return (
    <React.Fragment>
      <PageTitle pageTitle="Readsy - Reset Password" />
      <div className="bg-white dark:bg-gray-900 mx-auto content-center my-16">
        <div className="w-full max-w-sm mx-auto overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800">
          <div className="px-6 py-4">
            {errors.invalidToken ? (
              <h1>Invalid token, resubmit password reset email</h1>
            ) : (
              <form onSubmit={handleSubmit}>
                <div className="w-full mt-4">
                  <input
                    className={inputFormat}
                    type="username"
                    placeholder="Email"
                    aria-label="Email"
                    value={username}
                    required
                    autoComplete="on"
                    error={errors["username"]}
                    disabled
                  />
                </div>
                <div className="w-full mt-4">
                  <input
                    className={inputFormat}
                    type="password"
                    placeholder="Password"
                    aria-label="Password"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    required
                    autoComplete="off"
                    error={errors["password"]}
                  />
                </div>
                <div className="w-full mt-4">
                  <input
                    className={inputFormat}
                    type="password"
                    placeholder="Confirm Password"
                    aria-label="Confirm Password"
                    onChange={(e) => setPasswordConfirm(e.target.value)}
                    value={passwordConfirm}
                    required
                    error={errors["passwordConfirm"]}
                  />
                </div>

                <div className="flex items-center justify-between mt-4">
                  <button
                    className="px-4 py-2 leading-5 text-white transition-colors duration-200 transform bg-gray-700 rounded hover:bg-gray-600 focus:outline-none"
                    type="submit"
                    disabled={validate(
                      { username, password, passwordConfirm },
                      schema
                    )}
                  >
                    Save Password
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ResetPasswordForm;
