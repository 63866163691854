export const paginationButtonStyle = "h-6 w-6 border rounded-md mr-2";

export const navbarDropdownItemStyle =
  "block px-4 py-3 text-sm text-gray-600  break-words transition-colors duration-200 transform dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white";

export const navbarDropdownItemStyleFixed =
  "block px-4 py-3 text-sm text-gray-900  break-words font-bold transition-colors duration-200 transform dark:text-gray-300 ";

export const inputFormat =
  "block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-500 bg-white border rounded-md dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 focus:border-blue-400 dark:focus:border-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring focus:ring-blue-300";

export const inputFormatNoWidth =
  " px-4 py-2 mt-2 text-gray-700 placeholder-gray-500 bg-white border rounded-md dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 focus:border-blue-400 dark:focus:border-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring focus:ring-blue-300";

export const normalSubmitButtonStyle =
  "px-4 py-2 leading-5 text-white transition-colors duration-200 transform bg-gray-700 rounded hover:bg-gray-600 focus:outline-none";
