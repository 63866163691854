/*global chrome*/
import http from "./httpService";
import config from "../config.json";
import jwtDecode from "jwt-decode";

const tokenKey = "readsyToken";
const extensionId = "cmohjkgpkiehlngejpppkjfcdekbgnjf";
const extensionId2 = "nopgbkabhipfhbciolfjhmkflphjfkca";
http.setJwt(getJwt());

export async function login(email, password) {
  const { data } = await http.post(config.apiEndpoint + "/auth", {
    email,
    password,
  });
  console.log("data", data);
  localStorage.setItem(tokenKey, data.jwt);
}

export function getJwt() {
  return localStorage.getItem(tokenKey);
}

export function loginWithJwt(jwt) {
  let jwtTest = jwt;
  if (!jwtTest) {
    jwtTest = localStorage.getItem(tokenKey);
  }
  localStorage.setItem(tokenKey, jwtTest);
}

export function loginWithoutJwt() {
  try {
    let jwtTest = localStorage.getItem(tokenKey);
    localStorage.setItem(tokenKey, jwtTest);
  } catch (error) {
    console.log(error);
  }
}

export function logout() {
  localStorage.removeItem(tokenKey);
}

export function getCurrentUser() {
  try {
    const jwt = localStorage.getItem(tokenKey);
    return jwtDecode(jwt);
  } catch (ex) {
    return null;
  }
}

export function register(user) {
  return http.post(config.apiEndpoint + "/users/register", {
    email: user.username,
    password: user.password,
    firstName: user.firstName,
    lastName: user.lastName,
    emailNotifications: user.emailState,
  });
}

export function signup(user) {
  return http.post(config.apiEndpoint + "/users/signup", {
    email: user.email,
  });
}

export default {
  login,
  loginWithJwt,
  loginWithoutJwt,
  logout,
  getCurrentUser,
  getJwt,
  register,
  signup,
};
