import React, { useState } from "react";
import PageTitle from "./common/pageTitle";
import Accordion from "./common/accordion";
import { CheckCircleIcon } from "@heroicons/react/solid";
const tedImg = require("../media/tedImg.png");

const Team = () => {
  return (
    <>
      <PageTitle pageTitle="Readsy - Developer" />

      <section class="bg-white dark:bg-gray-900">
        <div class="container px-6 py-10 mx-auto">
          <div class="xl:flex xl:items-center xL:-mx-4">
            <div class="xl:w-1/2 xl:mx-4">
              <h1 class="text-3xl font-semibold text-gray-800 capitalize lg:text-4xl dark:text-white">
                Developer
              </h1>

              <p class="max-w-2xl mt-4 text-gray-500 dark:text-gray-300">
                I'm Ted, a programmer from Boston, MA.
                <br />
                <br />
                I love to read, but am very slow at it. Readsy is my attempt to change that.
              </p>
              <p class="max-w-2xl mt-4 text-gray-500 dark:text-gray-300">

                <a
                  href="https://www.buymeacoffee.com/emjp5f"
                  class="text-blue-500 hover:text-blue-700"
                >
                    buymeacoffee?
                </a>

              </p>
              
              
            </div>

            <div class="grid grid-cols-1 gap-8 mt-8 xl:mt-0 xl:mx-4 xl:w-full md:grid-cols-2">
              <div>
                <img
                  class="object-cover rounded-xl aspect-square"
                  src={tedImg}
                  alt=""
                />

                {/* <p class="mt-2 text-gray-500 capitalize dark:text-gray-300">
                  Developer
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Team;
