import React, { useState } from "react";
import {
  navbarDropdownItemStyle,
  navbarDropdownItemStyleFixed,
} from "./common/styleClasses";
const NavBarDropdown = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const hide = () => setIsOpen(false);
  const show = () => setIsOpen(true);
  return (
    <React.Fragment>
      <div className={props.dropdownStyle}>
        <button
          onClick={toggle}
          type="button"
          aria-controls="mobile-menu"
          aria-expanded="false"
          className={props}
        >
          {props.label}
        </button>
        <div className="relative inline-block mt-6">
          {!isOpen ? (
            <></>
          ) : (
            <div
              dir="rtl"
              className="nav-dropdown-list absolute right-0 py-2 mt-2 bg-white rounded-md shadow-xl dark:bg-gray-800"
            >
              <ul className="menu-links">
                {props.ddOptions.map((nav) =>
                  !nav.link ? (
                    <li key={nav.key}>
                      <span className={navbarDropdownItemStyleFixed}>
                        {nav.text}
                      </span>
                    </li>
                  ) : (
                    <li key={nav.key}>
                      <a
                        className={navbarDropdownItemStyle}
                        href={nav.link}
                        onClick={toggle}
                        onBlur={hide}
                        onFocus={show}
                      >
                        {nav.text}
                      </a>
                    </li>
                  )
                )}
              </ul>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default NavBarDropdown;
