import React, { useState } from "react";
import PageTitle from "./common/pageTitle";
import Accordion from "./common/accordion";
import { CheckCircleIcon } from "@heroicons/react/solid";
const readsyBtnImg = require("../media/readsyButton.png");
const readsyBtnPopImg = require("../media/readsyButtonPopup.png");
const readsyBtnSettingsImg = require("../media/readsyScreenshot_02.PNG");

const FAQ = () => {
  return (
    <>
      <PageTitle pageTitle="Readsy - FAQ" />
      <section className="bg-white dark:bg-gray-900">
        <div className="container px-6 py-10 mx-auto">
          <h1 className="text-4xl font-semibold text-gray-800 dark:text-white">
            FAQs
          </h1>

          <hr className="my-6 border-gray-200 dark:border-gray-700" />

          <div className="wrapper">
            <Accordion title="How do I use the extension?">
              <ul className="list-disc space-y-4">
                <li>
                  Readsy can be run in one of three ways: <br />
                  1) Click the floating Readsy button at the top left of any
                  webpage{" "}
                  <img
                    className="w-[6rem] h-[6rem] flex-shrink-0 object-cover xl:w-[6rem] xl:h-[6rem]"
                    // src="https://images.unsplash.com/photo-1581291518857-4e27b48ff24e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                    src={readsyBtnImg}
                    alt="Readsy screenshot example"
                  />
                  <br />
                  2) If you have closed the Readsy button, you can run Readsy by
                  going to the popup window and clicking on the Readsy logo
                  <img
                    className="w-[34rem] h-[18rem] flex-shrink-0 object-cover xl:w-[34rem] xl:h-[18rem]"
                    // src="https://images.unsplash.com/photo-1581291518857-4e27b48ff24e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                    src={readsyBtnPopImg}
                    alt="Readsy screenshot example"
                  />
                  <br />
                  3) Press/hold the 'R' key on your keyboard and click anywhere
                  on the webpage
                  <br />
                </li>
                <li>
                  Readsy's formatting can be toggled on / off by clicking the
                  Readsy button a second time (or using the popup button or
                  'R'+clicking)
                </li>
              </ul>{" "}
            </Accordion>
            <hr className="my-8 border-gray-200 dark:border-gray-700" />
            <Accordion title="Why was some text on the page not recognized at all?">
              <ul className="list-disc space-y-4">
                <li>
                  Browsers sometimes only display part of a page's text until a
                  user scrolls further down or clicks on a "Read more" button.
                  In this case, just click the Readsy button again to have it
                  re-analyze the page.
                </li>
                <li>
                  The text might actually be a picture/image of text instead of
                  machine readable words (Kindle's browser app unfortunately
                  displays pictures of text instead of actual text)
                </li>
                <li>
                  Readsy also looks for large paragraphs of text to analyze
                  which means some shorter paragraphs (based on word count) will
                  not be processed.
                </li>
              </ul>
            </Accordion>
            <hr className="my-8 border-gray-200 dark:border-gray-700" />
            <Accordion title="How does Readsy determine what to highlight?">
              <ul className="list-disc space-y-4">
                <li>
                  Readsy takes all HTML based text on a webpage and parses
                  through it word-by-word using a standard part-of-speech
                  library. It then highlights text based on those
                  classifications.
                </li>
              </ul>
            </Accordion>
            <hr className="my-8 border-gray-200 dark:border-gray-700" />
            <Accordion title="Do you track what I'm reading?">
              <ul className="list-disc space-y-4">
              <li>
                  Readsy tracks how often you are using the product (to prevent abuse of the API), but does not track or save any other data.
                  </li>
        
              </ul>
            </Accordion>
            <hr className="my-8 border-gray-200 dark:border-gray-700" />
            <Accordion title="Can the formatting be changed?">
              <ul className="list-disc space-y-4">
                <li>
                  Yes, click the green slide-out settings button to change the
                  formatting for any part-of-speech or use the popup settings
                  window that appears after clicking the top-right browser
                  button.
                </li>
                <li>
                  Once you create a format you like, enter a name in the input
                  field and save the format for later use. You can select
                  between several different types of formatting depending on the
                  type of content you are reading.
                </li>
                <img
                  className="w-[60rem] h-[40rem] flex-shrink-0 object-cover xl:w-[60rem] xl:h-[40rem]"
                  // src="https://images.unsplash.com/photo-1581291518857-4e27b48ff24e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                  src={readsyBtnSettingsImg}
                  alt="Readsy screenshot example"
                />
              </ul>
            </Accordion>
            <hr className="my-8 border-gray-200 dark:border-gray-700" />
            <Accordion title="The Readsy button that appears on each webpage is annoying, can I turn this off?">
              <ul className="list-disc space-y-4">
                <li>
                  Yes, go to the popup settings menu and toggle OFF the "Auto On
                  / Off" option.
                </li>
                <li>
                  Readsy can then be run from the popup window or by pressing
                  'R' on your keyboard and clicking on a webpage.
                </li>
              </ul>
            </Accordion>
            <hr className="my-8 border-gray-200 dark:border-gray-700" />
            <Accordion title="Did Kevin Malone inspire this tool?">
              <ul className="list-disc space-y-4">
                <li>
                  Yes. As Kevin wisely stated, "many small time make big time."
                  Now we finally have time to see world.
                </li>
              </ul>
            </Accordion>
            <hr className="my-8 border-gray-200 dark:border-gray-700" />
          </div>
        </div>
      </section>
    </>
  );
};

export default FAQ;
