import React, { useEffect, useContext } from "react";
import { useLocation, useNavigate, Navigate, Outlet } from "react-router-dom";
import auth from "../services/authService";
import AuthContext from "./authProvider";

const RequireAuth = ({ allowedRoles }) => {
  const { auth: authUsed, setAuth } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!authUsed) {
      const currentUser = auth.getCurrentUser();
      if (currentUser) {
        const accessToken = localStorage.getItem("readsyToken");

        setAuth({ ...currentUser, accessToken });
      } else {
        navigate("/login");
      }
    }
  });

  return (!allowedRoles && authUsed) ||
    authUsed?.roles?.find((role) => allowedRoles?.includes(role)) ? (
    <Outlet />
  ) : authUsed?.email ? (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  ) : (
    <></>
  );
};

export default RequireAuth;
